import _ from "lodash";
import moment, { Moment } from "moment";
import { FormEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Grid, Typography } from "@mui/material";

import {
  Action,
  AppointmentStatus,
  CreateAppointment,
  ErrorsObj,
  InputType,
  StylesEnum,
  UserType,
  EndpointGenerator
} from "@mapsy/shared";

import COLORS from "constants/colors";

import { CustomButton } from "./Button";
import { ModalLayout } from "layouts/ModalLayout";
import { selectSessionState } from "features/session/session.slice";
import { setToken } from "utils/setToken";
import { useAppSelector } from "hooks";
import { useAxios } from "hooks/useAxios";
import { Entity, Form } from "interfaces";
import { InputField } from "./InputField";
import { areInputsValid } from "utils/areInputsValid";
import { useAnalytics } from "hooks/useAnalytics";
import COMPONENTS from "constants/componentNames";
import { DATE_LONG_FORMAT } from "constants/defaultUserValues";

interface Props {
  newAppointmentDate: Moment;
  onSavedAppointment: (newAppointmentWeek: number) => void;
  onClose: () => void;
}

interface NewAppointmentValues {
  title: string;
  day: number;
  time: number;
}

export const NewAppointmentModal: React.FC<Props> = ({
  newAppointmentDate,
  onClose,
  onSavedAppointment: onSave,
}) => {
  const { postData, isLoading, errorMsg } = useAxios();
  const { createAnalytic } = useAnalytics();
  const { token, profileInfo } = useAppSelector(selectSessionState);
  const [errors, setErrors] = useState<ErrorsObj>();
  const [values, setValues] = useState<NewAppointmentValues>({
    title: "",
    day: newAppointmentDate.valueOf(),
    time: newAppointmentDate.valueOf(),
  });
  const [hasSucceeded, setHasSucceeded] = useState(false)

  useEffect(() => {
    setValues((_values) => {
      const prevHour = moment(_values.time).get("hour");
      return {
        ..._values,
        time: moment(_values.day).set("hour", prevHour).valueOf(),
      };
    });
  }, [values.day]);

  const inputs: Form = useMemo(
    () => [
      {
        propertyName: "title",
        label: "Título del evento",
        inputType: InputType.Text,
        placeholder: "Cita con ...",
        required: true,
        validation: {
          isRequired: true,
          minLength: 2,
          maxLength: 50,
        },
      },
      {
        propertyName: "day",
        label: "Día del evento",
        inputType: InputType.Select,
        autoFocus: false,
        menuItems: Array.from({ length: 7 }, (_, i) => {
          const day = moment(newAppointmentDate).add(i, "days");
          return {
            label: day.format(DATE_LONG_FORMAT),
            value: day.valueOf(),
          };
        }),
      },
      {
        propertyName: "time",
        label: "Hora del evento",
        inputType: InputType.Select,
        autoFocus: false,
        menuItems: Array.from({ length: 14 }, (_, i) => {
          const daySelected = values.day;
          const time = moment(daySelected).set("hours", i + 7);
          return {
            label: time.format("HH:mm"),
            value: time.valueOf(),
          };
        }),
      },
    ],
    [values, newAppointmentDate]
  );

  const handleChange = useCallback(
    (propertyName: keyof NewAppointmentValues | string, value: any) => {
      const parsedValue = propertyName === "title" ? value.toString() : +value;

      setValues((_values) => ({ ..._values, [propertyName]: parsedValue }));
    },
    []
  );

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();

      const therapistId =
        profileInfo?.type === UserType.Therapist && profileInfo?.id;

      if (!therapistId) {
        return;
      }

      if (
        !areInputsValid({
          inputs,
          values: values as unknown as Entity,
          setErrors,
        })
      ) {
        return;
      }

      const createAppointment: CreateAppointment = {
        date: values.time,
        therapistId,
        duration: 60,
        title: values.title,
        isBlocking: true,
        appointmentStatus: AppointmentStatus.Confirmed,
      };

      await postData(
        EndpointGenerator.AppointmentAPI.baseURL,
        createAppointment,
        setToken(token)
      );
      setHasSucceeded(true)

      createAnalytic({
        action: Action.SUBMIT,
        componentName: COMPONENTS.NEW_APPOINTMENT_MODAL,
        data: createAppointment,
      });

      onSave(newAppointmentDate.week());
      onClose();
    },
    [newAppointmentDate, values]
  );

  return (
    <ModalLayout
      isOpen={Boolean(newAppointmentDate)}
      onClose={onClose}
      maxWidthContainer="sm"
    >
      <form onSubmit={handleSubmit}>
        <Grid container sx={{ padding: 2, gap: 3 }}>
          <Grid item xs={12} sx={{ mb: 3 }}>
            <Typography
              sx={{ color: COLORS.BLUE_1, fontSize: "1.5rem", fontWeight: 500 }}
            >
              Crear cita
            </Typography>
          </Grid>
          {inputs.map(({ gridSize = { xs: 12 }, propertyName, ...rest }, i) => (
            <Grid
              xs={12}
              id={`row-input-container-${propertyName}-${i}`}
              item
              key={`row-input-container-${propertyName}-${i}`}
              sx={{ px: 1, my: 1.5 }}
            >
              <Grid container sx={{ flexDirection: "column" }}>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: "1.3rem" }}>
                    {rest.label}:
                  </Typography>
                </Grid>

                <Grid item xs={12} md={12}>
                  <InputField
                    backgroundMode="transparent"
                    propertyName={propertyName}
                    value={_.get(values, propertyName, "")}
                    handleChange={handleChange}
                    helperText={errors && errors[propertyName]}
                    {...rest}
                    label=""
                  />
                </Grid>
              </Grid>
            </Grid>
          ))}
          {errorMsg && (
            <Grid item xs={12}>
              <Typography>{errorMsg}</Typography>
            </Grid>
          )}

          <Grid
            item
            xs={12}
            sx={{ mt: 3, display: "flex", justifyContent: "right" }}
          >
            <CustomButton
              customStyle={StylesEnum.primary}
              sx={{ px: 1 }}
              hasSucceeded={hasSucceeded}
              type="submit"
              isLoading={isLoading}
            >
              Continuar
            </CustomButton>
          </Grid>
        </Grid>
      </form>
    </ModalLayout>
  );
};
