import { Box } from "@mui/material";
import PromotionBanner from "components/molecules/PromotionBanner";
import { Find } from "components/organisms/Find";
import { How } from "components/organisms/How";
import { Meet } from "components/organisms/Meet";
import { Reviews } from "components/organisms/Reviews";
import { MeetProps } from "interfaces";
import { usePromotions } from "providers/PromotionsProvider";

export const Home = () => {
  const { currentClosestPromotion, showBanner, alreadyStarted } =
    usePromotions();

  const meetProps: MeetProps = {
    title: "Conoce a los psicólogos de Mapsy",
    button: { label: "Comenzar", href: "/reason" },
    paragraphs: [
      {
        children: `Contamos con profesionales altamente cualificados
      y `,
        sx: {},
        component: "span",
      },
      {
        children: `comprometidos con tu bienestar emocional.`,
        sx: { fontWeight: 500 },
        component: "span",
      },
      {
        children: <br />,
        sx: {},
      },
      {
        children: `Cada miembro de nuestro equipo posee una
      sólida formación académica que es validada por un `,
        sx: {},
        component: "span",
      },
      {
        children: `proceso de
      verificación.`,
        sx: { fontWeight: 500 },
        component: "span",
      },
    ],
  };

  return (
    <Box>
      {currentClosestPromotion && showBanner && (
        <PromotionBanner
          promotion={currentClosestPromotion}
          alreadyStarted={alreadyStarted}
        />
      )}
      <Find />
      <How />
      <Meet
        title={meetProps.title}
        button={meetProps.button}
        paragraphs={meetProps.paragraphs}
      />
      {/* <Reviews /> // Se comenta para el MVP1 hasta que tengamos reviews reales */}
    </Box>
  );
};
