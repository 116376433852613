import _ from "lodash";
import { Entity, GetStartedProps, PossibleUsers } from "interfaces";
import { CircularProgress, Stack, Typography } from "@mui/material";
import {
  UserType,
  StylesEnum,
  ErrorMsg,
  Subscription,
  EndpointGenerator,
} from "@mapsy/shared";
import { CustomButton } from "components/atoms/Button";
import COLORS from "constants/colors";
import { useAxios } from "hooks/useAxios";
import { homePageByUserType } from "pages/SignInAndUp";
import { useCallback, useEffect, useState } from "react";
import { setToken } from "utils/setToken";
import { selectSessionState } from "features/session/session.slice";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useValidation } from "providers/FormProvider";
import { useQuery } from "hooks/useQuery";

export const Final: React.FC<GetStartedProps> = ({
  userInfo,
  userType,
  changePage,
  onSucceededRegistration,
}) => {
  const query = useQuery();
  const { state } = useLocation();
  const [validationError, setValidationError] = useState("");
  const {
    isLoading: accountLoading,
    patchData: updateAccount,
    errorMsg: accountError,
  } = useAxios({ loadingDefault: false });
  const nav = useNavigate();
  const { token } = useSelector(selectSessionState);

  const { setShowErrors, formValid } = useValidation();

  const completeAccount = useCallback(
    async (user: Entity | PossibleUsers) => {
      const endpoint =
        userType === UserType.Therapist
          ? EndpointGenerator.TherapistAPI.urlById(userInfo._id)
          : EndpointGenerator.PatientAPI.urlById(userInfo._id);

      user.accountStatus =
        user.accountStatus && typeof user.accountStatus === "object"
          ? { ...user.accountStatus, registrationComplete: true }
          : {
              emailVerified: false,
              hasProfilePic: false,
              isActive: true,
              phoneVerified: false,
              subscription: Subscription.Free,
              registrationComplete: true,
            };

      const userCopy = { ...user };
      delete userCopy.password;

      const response = await updateAccount(
        endpoint,
        userCopy,
        setToken(token),
        {
          409: ErrorMsg.EmailAlreadyInUse,
          500: ErrorMsg.InternalServer,
          400: ErrorMsg.AccountGeneric,
        }
      );
      onSucceededRegistration?.();

      if (response) {
        const redirectTo = query.getRedirectToWithParams();
        const navPath = redirectTo ? redirectTo : homePageByUserType[userType];
        nav(navPath, { state });
      }

      // si llegamos aqui es que fallo el patch
    },
    [userType, token, state]
  );

  useEffect(() => {
    const create = async () => {
      if (!formValid) {
        setValidationError(
          "Aún no completas todos los campos requeridos. Da click en Regresar para completarlos."
        );
        setShowErrors(true);
        return;
      }

      setValidationError("");
      setShowErrors(false);

      await completeAccount(userInfo);
    };

    create();
  }, [userInfo, formValid]);

  return (
    <Stack sx={{ textAlign: "center", alignItems: "center" }}>
      <Typography
        sx={{
          fontSize: "2rem",
          color: COLORS.BLUE_1,
          textAlign: "center",
          mb: 4,
        }}
      >
        Espera mientras preparamos todo.{" "}
        {userType === UserType.Therapist
          ? "¡Serás redirigido a tu calendario!"
          : "¡En un momento podrás encontrar a tu terapeuta ideal!"}
      </Typography>
      {!accountLoading ? (
        <>
          <Typography variant="body1" sx={{ color: COLORS.TEXT_RED }}>
            {accountError || validationError}
          </Typography>
          <CustomButton
            customStyle={StylesEnum.secondary}
            children={"Regresar"}
            disableRipple={true}
            isLoading={false}
            onClick={() => changePage({}, true)}
            sx={{
              mt: 4,
              borderRadius: "14px",
            }}
          />
        </>
      ) : (
        <CircularProgress sx={{ mt: 6 }} size={70} thickness={2} />
      )}
    </Stack>
  );
};
