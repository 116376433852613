import moment from "moment";
import { useCallback } from "react";
import { Grid, Typography } from "@mui/material";

import { Appointment, AppointmentStatus, StylesEnum, EndpointGenerator } from "@mapsy/shared";

import COLORS from "constants/colors";

import { CustomButton } from "./Button";
import { ModalLayout } from "layouts/ModalLayout";
import { selectSessionState } from "features/session/session.slice";
import { setToken } from "utils/setToken";
import { useAppSelector } from "hooks";
import { useAxios } from "hooks/useAxios";

interface Props {
  appointmentToCancel: Appointment;
  onClose: () => void;
  onCanceledAppointment: (weekToUpdate: number) => void;
}

export const CancelAppointmentModal: React.FC<Props> = ({
  appointmentToCancel,
  onCanceledAppointment,
  onClose,
}) => {
  const { patchData, isLoading, errorMsg } = useAxios();
  const { token } = useAppSelector(selectSessionState);

  const handleCancelAppointment = useCallback(async () => {
    if (!token || !appointmentToCancel) {
      return;
    }

    const endpoint = EndpointGenerator.AppointmentAPI.urlById(
      appointmentToCancel._id
    );
    const data: string = await patchData(
      endpoint,
      {
        appointmentStatus: AppointmentStatus.Canceled,
      },
      setToken(token)
    );

    if (!data) {
      return;
    }

    const week = moment(appointmentToCancel.date).week();

    onCanceledAppointment(week);
  }, [appointmentToCancel, token]);

  return (
    <ModalLayout
      isOpen={Boolean(appointmentToCancel._id)}
      onClose={onClose}
      maxWidthContainer="sm"
    >
      <Grid container sx={{ padding: 2, gap: 6 }}>
        <Grid item xs={12}>
          <Typography
            sx={{ color: COLORS.BLUE_1, fontSize: "1.5rem", fontWeight: 500 }}
          >
            Cancelar cita
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", gap: 1, flexDirection: "column" }}
        >
          <Typography sx={{ fontSize: "1.2rem" }}>
            ¿Estás seguro de querer cancelar la cita?
          </Typography>
          <Typography sx={{ fontSize: "0.9rem" }}>
            Se enviará un mensaje a tu paciente para notificarlo.
          </Typography>
          {errorMsg && <Typography>{errorMsg}</Typography>}
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ mt: 3, display: "flex", justifyContent: "right" }}
        >
          <CustomButton
            customStyle={StylesEnum.primary}
            sx={{ px: 1 }}
            onClick={handleCancelAppointment}
            isLoading={isLoading}
          >
            Continuar
          </CustomButton>
        </Grid>
      </Grid>
    </ModalLayout>
  );
};
