import React, { useCallback, useMemo } from "react";
import moment from "moment";
import momentTz from "moment-timezone";
import {
  Box,
  Button,
  capitalize,
  Grid,
  IconButton,
  Popover,
  Typography,
} from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import {
  Appointments,
  AppointmentTooltip,
  DateNavigator,
  Toolbar,
  ViewSwitcher,
  WeekView,
} from "@devexpress/dx-react-scheduler-material-ui";
import {
  CurrentTimeIndicator,
  FormatterFn,
} from "@devexpress/dx-react-scheduler";
import { TodayButton } from "@devexpress/dx-react-scheduler-material-ui";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SettingsIcon from "@mui/icons-material/Settings";

import filledUser from "assets/svg/filled-user.svg";
import calendar from "assets/svg/calendar.svg";
import document from "assets/svg/document.svg";
import COLORS from "constants/colors";
import { ModalLayout } from "layouts/ModalLayout";
import { CalendarAppointment } from "pages/MyCalendar";
import {
  Appointment,
  AppointmentStatus,
  InputType,
  Modality,
  ModalitySpanish,
  ProvidedServiceTypesSpanish,
  StylesEnum,
  formatTimezonesEs,
} from "@mapsy/shared";
import { CustomButton } from "./Button";
import { capitalizeName } from "utils/capitalize";
import { DATE_LONG_FORMAT } from "constants/defaultUserValues";
import { InputField } from "./InputField";

export const StyledWeekViewDayScaleCell = styled(WeekView.DayScaleCell)({
  [`& .Cell-dayView`]: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    padding: "0.3rem",
  },
  [`& .Cell-dayOfMonth`]: {
    fontSize: "0.9rem",
  },
  [`& .Cell-dayOfWeek`]: {
    fontSize: "1rem",
    fontWeight: 500,
  },
});

export const StyledToolbarFlexibleSpace = styled(Toolbar.FlexibleSpace)(() => ({
  [`&.FlexibleSpace-flexibleSpace`]: {
    display: "none",
  },
}));

export const StyledDateNavigatorOpenButton = styled(DateNavigator.OpenButton)(
  () => ({
    [`&.OpenButton-textButton`]: {
      color: COLORS.WHITE,
      textTransform: "none",
    },
  })
);

export const StyledViewSwitcher = styled(ViewSwitcher.Switcher)(() => ({
  [`&.Switcher-inputRoot`]: {
    backgroundColor: COLORS.WHITE,
  },
}));

const StyledAppointment = styled(Appointments.Appointment)(() => ({
  [`&.Appointment-clickableAppointment`]: {
    borderRadius: 0,
    fontWeight: 400,
    display: "flex",
    gap: 2,
    borderColor: COLORS.BLUE_2,
    transition: "all 0.2s ease",
    "&:hover": {
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      borderBottom: "3px solid rgba(0, 0, 0, 0.5)",
    },
  },
}));

const StyledTimeTableCell = styled(WeekView.TimeTableCell)(() => ({
  [`&.MuiTableCell-body`]: {
    height: "80px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: alpha(COLORS.BLUE_1, 0.1),
    },
    "&:focus": {
      backgroundColor: alpha(COLORS.BLUE_1, 0.2),
    },
  },
}));

const StyledDiv = styled("div")(({ theme }) => ({
  [`& .line`]: {
    height: "2px",
    borderTop: `2px ${theme.palette.primary.main} dotted`,
    width: "100%",
    transform: "translate(0, -12.5px)",
  },
  [`& .circle`]: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    borderRadius: "50%",
    transform: "translate(-50%, -50%)",
    background: theme.palette.primary.main,
  },
  "&.nowIndicator": {
    position: "absolute",
    zIndex: 1,
    width: "100%",
  },
}));

export const TimeIndicator: React.FC<CurrentTimeIndicator.IndicatorProps> = ({
  top,
  ...restProps
}) => (
  <StyledDiv {...restProps} style={{ top }} className="nowIndicator">
    <div className="circle" />
    <div className="line" />
  </StyledDiv>
);

export const formatDayScaleDate: FormatterFn = (date, options) => {
  const momentDate = moment(date);
  const { weekday } = options;
  return capitalize(momentDate.format(weekday ? "ddd" : "D [de] MMMM"));
};

export const DayScaleCell = ({ ...restProps }) => (
  <StyledWeekViewDayScaleCell
    startDate={moment().toDate()}
    {...restProps}
    formatDate={formatDayScaleDate}
  />
);

export const FlexibleSpace = ({ ...restProps }) => (
  <StyledToolbarFlexibleSpace {...restProps} />
);

export const CustomTodayButton: React.FC<TodayButton.ButtonProps> = ({
  getMessage,
  setCurrentDate,
}) => (
  <Button
    onClick={() => setCurrentDate(moment().toDate())}
    sx={{
      backgroundColor: COLORS.WHITE,
      p: 1,
      height: "min-content",
      color: "black",
      ":hover": {
        color: COLORS.WHITE,
      },
    }}
  >
    Hoy
  </Button>
);

export const CustomDateNavigationButton: React.FC<
  DateNavigator.NavigationButtonProps
> = ({ type, onClick }) => (
  <Button
    onClick={onClick}
    sx={{ color: COLORS.WHITE }}
    children={type === "back" ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
  />
);

interface ConfigPopoverProps {
  optionalTz: string[];
  currentTz: string;
  setCurrentTz: (tz: string) => void;
}

export const CustomConfigPopover: React.FC<ConfigPopoverProps> = ({
  optionalTz,
  currentTz,
  setCurrentTz,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const formattedOptionalTz = useMemo(
    () => optionalTz.map((optionTz) => formatTimezonesEs(optionTz)),
    [optionalTz]
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "config-popover" : undefined;

  const handleChangeSwitch = useCallback((pName: string, value: any) => {
    setCurrentTz(value ? optionalTz[1] : optionalTz[0]);
  }, []);

  return (
    <div>
      <IconButton aria-describedby={id} onClick={handleClick}>
        <SettingsIcon sx={{ color: COLORS.WHITE }} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {optionalTz.length === 1 ? (
          <Typography>Hora {formattedOptionalTz[0]}</Typography>
        ) : (
          <Box
            sx={{
              p: 2,
              display: "grid",
              gridTemplateColumns: "100px auto 100px",
              textAlign: "center",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Typography>Hora {formattedOptionalTz[0]}</Typography>
            <InputField
              inputType={InputType.Switch}
              propertyName="currentTz"
              value={currentTz === optionalTz[1]}
              handleChange={handleChangeSwitch}
            />
            <Typography>Hora {formattedOptionalTz[1]}</Typography>
          </Box>
        )}
      </Popover>
    </div>
  );
};

export const CustomRootToolbar: React.FC<
  Toolbar.RootProps & ConfigPopoverProps
> = ({ children, ...rest }) => (
  <Box
    sx={{
      display: "flex",
      p: 1,
      backgroundColor: COLORS.BLUE_1,
      color: "white",
      borderRadius: "8px",
      justifyContent: "space-around",
      alignItems: "center",
    }}
  >
    {children}
  </Box>
);

export const CustomDateNativatorOpenButton: React.FC<
  DateNavigator.OpenButtonProps
> = ({ ...restProps }) => <StyledDateNavigatorOpenButton {...restProps} />;

export const CustomViewSwitcher: React.FC<ViewSwitcher.SwitcherProps> = ({
  ...restProps
}) => <StyledViewSwitcher {...restProps} />;

export const CustomAppointment: React.FC<
  Appointments.AppointmentProps & {
    className?: string;
    style?: React.CSSProperties;
    [x: string]: any;
  }
> = ({ style, ...restProps }) => {
  const data = restProps.data as CalendarAppointment;
  const opacity = data.date < moment().valueOf() ? 0.8 : 1; // reduce opacity if appointment is the past

  return (
    <StyledAppointment
      {...restProps}
      style={{
        backgroundColor:
          data.appointmentStatus === AppointmentStatus.Canceled
            ? alpha(COLORS.YELLOW, opacity)
            : alpha(COLORS.BLUE_2, opacity),
      }}
    />
  );
};

export const CustomTimeTableCell: React.FC<
  WeekView.TimeTableCellProps & {
    handleClick: (date?: Date) => void;
    noWorkableDays?: number;
    unavailableLocations: number;
  }
> = (props) => {
  const {
    startDate,
    handleClick,
    noWorkableDays,
    unavailableLocations,
    ...restProps
  } = props;

  return (
    <StyledTimeTableCell
      startDate={startDate}
      {...restProps}
      sx={{
        backgroundColor: noWorkableDays
          ? alpha(COLORS.DISABLED_GREY, noWorkableDays / 2)
          : alpha(COLORS.DISABLED_GREY, unavailableLocations / 4),
      }}
      onClick={() => handleClick(startDate)}
    />
  );
};

export const CustomTooltipLayout: React.FC<
  AppointmentTooltip.LayoutProps & {
    className?: string;
    style?: React.CSSProperties;
    [x: string]: any;
  } & {
    onCancelButtonClicked: (appointment: Appointment) => void;
    therapistTimezone?: string;
    currentTz: string;
  }
> = ({
  appointmentMeta,
  visible,
  onHide,
  onCancelButtonClicked,
  therapistTimezone,
  currentTz,
}) => {
  const data = appointmentMeta?.data as CalendarAppointment | undefined;
  const location = useMemo(
    () => data?.therapist?.locations.find(({ _id }) => _id === data.locationId),
    [data?.therapist, data?.locationId]
  );

  if (!data) {
    return <></>;
  }

  const {
    date,
    startDate,
    endDate,
    patient,
    providedService,
    consultingReason,
    appointmentStatus,
    title,
  } = data;

  return (
    <ModalLayout
      isOpen={Boolean(visible)}
      onClose={onHide}
      maxWidthContainer="md"
    >
      <Grid container sx={{ padding: 2, gap: 3 }}>
        <Grid item xs={12}>
          <Typography
            sx={{ color: COLORS.BLUE_1, fontSize: "1.5rem", fontWeight: 500 }}
          >
            Datos de la cita
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container gap={2}>
            <Grid item xs={1}>
              <img src={filledUser} alt="user icon" />
            </Grid>
            <Grid
              item
              xs={10}
              sx={{
                display: "flex",
                justifyContent: "space-around",
                flexDirection: "column",
                gap: 1,
              }}
            >
              {patient ? (
                <>
                  <Typography>
                    {capitalizeName([
                      patient.firstName,
                      patient.middleName,
                      patient.lastName,
                    ])}
                  </Typography>
                  <Typography>
                    {`${patient.phone.dial_code} ${patient.phone.number}`}
                  </Typography>
                  <Typography>{patient.email}</Typography>
                </>
              ) : (
                <Typography>{title || "Sin título"}</Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container gap={2}>
            <Grid item xs={1}>
              <img src={calendar} alt="user icon" />
            </Grid>
            <Grid
              item
              xs={10}
              sx={{
                display: "flex",
                justifyContent: "space-around",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <Typography>
                Terapia{" "}
                {providedService
                  ? ProvidedServiceTypesSpanish[providedService.serviceType]
                  : ""}
              </Typography>
              <Typography>
                {capitalize(moment(date).format(DATE_LONG_FORMAT))}
              </Typography>
              <Typography>
                {momentTz(startDate).tz(currentTz).format("HH:mm a")} -{" "}
                {momentTz(endDate).tz(currentTz).format("HH:mm a")} (
                {formatTimezonesEs(currentTz)})
              </Typography>
              {therapistTimezone && therapistTimezone !== currentTz && (
                <Typography>
                  {momentTz(startDate).tz(therapistTimezone).format("HH:mm a")}{" "}
                  - {momentTz(endDate).tz(therapistTimezone).format("HH:mm a")}{" "}
                  ({formatTimezonesEs(therapistTimezone)})
                </Typography>
              )}
              <Typography>
                {location?.modality !== undefined
                  ? ModalitySpanish[location.modality]
                  : "Modalidad no disponible"}
              </Typography>
              {location && location.modality === Modality.Presencial && (
                <Typography>
                  {location.name}: {location.street} {location.externalNo},{" "}
                  {location.community}, {location.city}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container gap={2}>
            <Grid item xs={1}>
              <img src={document} alt="user icon" />
            </Grid>
            <Grid
              item
              xs={10}
              sx={{
                display: "flex",
                justifyContent: "space-around",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <Typography>Motivo de consulta:</Typography>
              <Typography>
                {consultingReason || "Motivo de consulta no disponible"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ mt: 3, display: "flex", justifyContent: "right" }}
        >
          {appointmentStatus !== AppointmentStatus.Canceled ? (
            <CustomButton
              customStyle={StylesEnum.primary}
              sx={{ px: 1 }}
              disabled={moment().valueOf() > date}
              onClick={() => {
                onCancelButtonClicked({ ...data });
                onHide?.();
              }}
            >
              Cancelar cita
            </CustomButton>
          ) : (
            <Typography>Cita cancelada</Typography>
          )}
        </Grid>
      </Grid>
    </ModalLayout>
  );
};
