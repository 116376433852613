import { APINames, UserType } from "../..";
import BaseAPI from "./BaseEndpointAPI";

class ResetTokenAPI extends BaseAPI {
  constructor() {
    super(APINames.RESETTOKEN);
  }

  validateToken(token: string, email: string, userType: UserType) {
    const query = new URLSearchParams({ email, userType } as any);
    return `${this.baseURL}/validate/${token}?${query}`;
  }
}

const resetTokenAPI = new ResetTokenAPI();
export default resetTokenAPI;
