import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Container, Grid, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

import COLORS from "../constants/colors";
import { CustomButton } from "../components/atoms/Button";
import logo from "../assets/img/logo_without_label.png";
import { useAxios } from "../hooks/useAxios";
import { useAppDispatch } from "../hooks";
import {
  resetTherapistsPagination,
  setNewConsultingActions,
} from "../features/reason/reasonSice";
import { Action, ErrorMsg, InputType, StylesEnum, Topic, EndpointGenerator } from "@mapsy/shared";
import { AppDispatch } from "store";
import { useAnalytics } from "hooks/useAnalytics";
import COMPONENTS from "constants/componentNames";
import { useValidation, ValidationProvider } from "providers/FormProvider";
import { Form, FormInput } from "interfaces";
import { InputField } from "components/atoms/InputField";

const ReasonBody: React.FC<{
  inputs: Form;
  reason: string;
  setReason: (arg: string) => void;
}> = ({ inputs, reason, setReason }) => {
  const { validateEntity } = useValidation();

  useEffect(() => {
    validateEntity({ reason }, inputs);
  }, []);

  return (
    <>
      {inputs.map(({ gridSize, ...rest }: FormInput, i: number) => (
        <Grid
          {...gridSize}
          item
          key={`input-${rest.propertyName}-${i}`}
          width={"100%"}
        >
          <InputField
            backgroundMode="no-border"
            {...rest}
            value={reason}
            handleChange={(pName, value, _) => setReason(value)}
          />
        </Grid>
      ))}
    </>
  );
};

export const Reason = () => {
  const dispatch: AppDispatch = useAppDispatch();
  const nav = useNavigate();
  const { createAnalytic } = useAnalytics();
  const { postData, isLoading, errorMsg } = useAxios();
  const [reason, setReason] = useState<string>("");
  const [isReasonInvalid, setIsReasonInvalid] = useState<
    ErrorMsg.InvalidConsultingReason | undefined
  >();

  const handleSubmit = useCallback(async () => {
    if (!reason) {
      return;
    }
    const url = EndpointGenerator.IAAPI.baseURL;
    const topic: Topic = await postData(url, {
      data: reason,
    });
    if (!topic) {
      return;
    }
    const { _id, name, longNames } = topic;

    createAnalytic({
      action: Action.SUBMIT,
      componentName: COMPONENTS.CONSULTING_REASON,
      data: {
        reason,
        topic: {
          name,
          _id,
          longNames,
        },
      },
    });

    if (name === "inválido") {
      setIsReasonInvalid(ErrorMsg.InvalidConsultingReason);
      return;
    }

    setIsReasonInvalid(undefined);
    dispatch(
      setNewConsultingActions({
        reason,
        predictedTopic: name,
        predictedTopicId: _id,
      })
    );
    dispatch(resetTherapistsPagination());

    nav("/suggested-therapists");
    return true;
  }, [reason]);

  const inputs: Form = useMemo(
    () => [
      {
        propertyName: "reason",
        label: "",
        placeholder: "Escribe aquí tu motivo de consulta",
        inputType: InputType.Textarea,
        gridSize: {
          md: 12,
          xs: 12,
        },
        validation: {
          isRequired: true,
          minLength: 10,
          maxLength: 300,
          formatErrorMsg: ErrorMsg.NumbersNotAllowed,
        },
      },
    ],
    []
  );

  return (
    <Box
      sx={{
        width: "100%",
        paddingY: "2rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: COLORS.WHITE,
      }}
    >
      <ValidationProvider handleSubmit={handleSubmit}>
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <img src={logo} alt="mapsy" style={{ maxHeight: "150px" }} />

          <Typography
            sx={{
              fontSize: "1.5rem",
              fontStyle: "normal",
              fontFamily: "Poppins",
            }}
          >
            Describe detalladamente el motivo por el cual buscas terapia. 
          </Typography>
          <Typography variant="body2">
            Por ejemplo: siento mucho estrés, tengo problemas con mi pareja, mi hijo no me hace caso.
          </Typography>
          <Grid
            container
            sx={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "1rem",
              maxWidth: "md",
            }}
          >
            <ReasonBody inputs={inputs} reason={reason} setReason={setReason} />
            {errorMsg && (
              <Grid item xs={12}>
                <Typography
                  sx={{ color: COLORS.TEXT_RED, alignSelf: "flex-start" }}
                >
                  {errorMsg}
                </Typography>
              </Grid>
            )}
            {isReasonInvalid && (
              <Grid item xs={12}>
                <Typography
                  sx={{ color: COLORS.TEXT_RED, alignSelf: "flex-start" }}
                >
                  {isReasonInvalid}
                </Typography>
              </Grid>
            )}
          </Grid>
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
              justifyContent: "left",
              alignItems: "center",
              width: "100%",
              maxWidth: "md",
            }}
          >
            <InfoIcon fontSize="small" />
            <Typography sx={{ fontSize: "0.8rem" }}>
              Tus respuestas son totalmente privadas
            </Typography>
          </Box>

          <CustomButton
            type="submit"
            customStyle={StylesEnum.primary}
            children={"Buscar"}
            isLoading={isLoading}
            sx={{ marginTop: "2rem" }}
          />
        </Container>
      </ValidationProvider>
    </Box>
  );
};
