import React, { useCallback, useState } from "react";
import Countdown from "components/atoms/Countdown";
import { Action, Promotion, StylesEnum } from "@mapsy/shared";
import { alpha, Box, Typography } from "@mui/material";
import COLORS from "constants/colors";
import { ModalLayout } from "layouts/ModalLayout";
import { CustomButton } from "components/atoms/Button";
import { useNavigate } from "react-router-dom";
import { useAnalytics } from "hooks/useAnalytics";
import COMPONENTS from "constants/componentNames";
import { CustomLink } from "components/atoms/Link";

interface Props {
  promotion: Promotion;
  alreadyStarted: boolean;
}
const PromotionBanner: React.FC<Props> = ({ promotion, alreadyStarted }) => {
  const nav = useNavigate();
  const { createAnalytic } = useAnalytics();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const {
    bannerConfig: { title, text },
    name,
    startDate,
    endDate,
    promoTermsOfUsePath,
  } = promotion;

  const handleClick = useCallback(() => {
    nav("/reason");
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
    createAnalytic({
      action: Action.CLOSE,
      componentName: COMPONENTS.PROMOTION_BANNER,
      data: {
        promotion: { title, name, startDate, endDate },
      },
    });
  }, []);

  return (
    <ModalLayout
      isOpen={isModalOpen}
      onClose={handleCloseModal}
      maxWidthContainer="sm"
    >
      <Box
        sx={{
          my: 1,
          padding: { md: "1rem", xs: "0.8rem" },
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 1.5,
        }}
      >
        <Typography
          sx={{ fontSize: "1.8rem", fontWeight: 600, fontStyle: "italic" }}
        >
          🎉 ¡Promoción! 🎉
        </Typography>
        <Box
          sx={{
            backgroundColor: alpha(COLORS.YELLOW, 0.75),
            textAlign: "center",
            color: COLORS.PURE_WHITE,
            borderRadius: "8px",
            p: { md: 2, xs: 1 },
          }}
        >
          <Typography sx={{ fontSize: "1.8rem", fontWeight: 600 }}>
            {title}
          </Typography>
        </Box>
        <Typography
          variant="body1"
          sx={{ fontSize: "1.56rem", fontWeight: 500 }}
        >
          {text}
        </Typography>
        {alreadyStarted ? (
          <>
            <Typography>Solo quedan:</Typography>
            <Countdown date={endDate} />
          </>
        ) : (
          <>
            <Typography>Faltan:</Typography>
            <Countdown date={startDate} />
          </>
        )}
        <CustomButton
          customStyle={StylesEnum.primary}
          onClick={handleClick}
          sx={{ mt: 4, mb: 2 }}
        >
          Buscar terapeuta
        </CustomButton>
        <Box>
          <Typography variant="caption">
            Consulta los{" "}
            <CustomLink to={promoTermsOfUsePath} underline>
              términos y condiciones de la promoción
            </CustomLink>
          </Typography>
        </Box>
      </Box>
    </ModalLayout>
  );
};

export default PromotionBanner;
