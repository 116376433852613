import { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import momentTz from "moment-timezone";
import {
  Box,
  Container,
  LinearProgress,
  Paper,
  Typography,
} from "@mui/material";
import {
  Appointments,
  AppointmentTooltip,
  DateNavigator,
  DayView,
  MonthView,
  Scheduler,
  Toolbar,
  ViewSwitcher,
  WeekView,
} from "@devexpress/dx-react-scheduler-material-ui";
import {
  AppointmentModel,
  CurrentTimeIndicator,
  ViewState,
} from "@devexpress/dx-react-scheduler";
import { TodayButton } from "@devexpress/dx-react-scheduler-material-ui";
import { EndpointGenerator } from "@mapsy/shared";

import {
  Appointment as MapsyAppointment,
  GetAllPagedResult,
  OrderEnum,
  WorkSchedule,
  WeekDaysEnum,
  Action,
} from "@mapsy/shared";
import { selectSessionState } from "features/session/session.slice";
import { selectTherapistState } from "features/therapist/therapist.slice";
import { setToken } from "utils/setToken";
import { useAxios } from "hooks/useAxios";
import { useSelector } from "react-redux";
import { ViewNames } from "enums/calendar.enum";
import {
  DayScaleCell,
  CustomRootToolbar,
  FlexibleSpace,
  CustomViewSwitcher,
  CustomDateNavigationButton,
  CustomDateNativatorOpenButton,
  CustomTodayButton,
  CustomTooltipLayout,
  CustomAppointment,
  CustomTimeTableCell,
  TimeIndicator,
} from "components/atoms/CalendarCustomComponents";
import { CancelAppointmentModal } from "components/atoms/CancelAppointmentModal";
import { NewAppointmentModal } from "components/atoms/NewAppointmentModal";
import { useAnalytics } from "hooks/useAnalytics";
import { getUnavailableLocationsLength } from "utils/getUnavailableLocationsLength";
import COMPONENTS from "constants/componentNames";
import { CustomLink } from "components/atoms/Link";
import _ from "lodash";

const MAX_SESSIONS_PER_WEEK = 60;

interface HoursRange {
  startDayHour: number;
  endDayHour: number;
}

export type CalendarAppointment = MapsyAppointment & AppointmentModel;

const currentTz = momentTz.tz.guess();

const MyCalendar: React.FC = () => {
  const { createAnalytic } = useAnalytics();
  const { token, profileInfo } = useSelector(selectSessionState);
  const { therapist } = useSelector(selectTherapistState);
  const { getData, isLoading } = useAxios();
  const [appointmentsByWeek, setAppointmentsByWeek] = useState<
    Record<number, Array<CalendarAppointment>>
  >({});
  const [week, setWeek] = useState(moment().week());
  const currentDate = useMemo(() => moment().toDate(), []);
  const [appointmentToCancel, setAppointmentToCancel] =
    useState<MapsyAppointment>();
  const [newAppointmentDate, setNewAppointmentDate] = useState<Date>();
  const unavailableLocations: Record<string, number> = useMemo(() => ({}), []);

  const optionalTz = useMemo(() => {
    const defaultTz = momentTz.tz.guess();
    if (!therapist?.timezone) {
      return [defaultTz];
    }
    return [defaultTz, therapist?.timezone];
  }, [therapist]);
  const [currentTz, setCurrentTz] = useState(optionalTz[0]);

  const hoursRange: HoursRange = useMemo(
    () => ({
      startDayHour: 7,
      endDayHour: 22,
    }),
    []
  );

  const noWorkableDays: Record<keyof WorkSchedule, number> = useMemo(() => {
    const _noWorkableDays: Record<keyof WorkSchedule, number> = {
      monday: 0, //number of no workable days
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      sunday: 0,
    };

    if (!therapist?.locations) {
      return _noWorkableDays;
    }

    therapist?.locations.forEach((loc) => {
      const { workSchedule } = loc;

      for (const weekday in workSchedule) {
        const schedule = workSchedule[weekday as keyof WorkSchedule];

        if (schedule.workable) {
          continue;
        }

        _noWorkableDays[weekday as keyof WorkSchedule] =
          _noWorkableDays[weekday as keyof WorkSchedule] + 1;
      }
    });

    return _noWorkableDays;
  }, [therapist?.locations]);

  const fetchAppointments = useCallback(
    async (week: number) => {
      if (!token || !profileInfo?.id) {
        return;
      }

      const data: GetAllPagedResult<MapsyAppointment> = await getData(
        EndpointGenerator.AppointmentAPI.byTherapist(
          {
            orderBy: "createdAt",
            order: OrderEnum.ASC,
            week,
            page: 1,
            limit: MAX_SESSIONS_PER_WEEK,
          },
          profileInfo.id
        ),
        setToken(token)
      );

      if (!data?.results) {
        return;
      }

      setAppointmentsByWeek((_appointmentsByWeek) => ({
        ..._appointmentsByWeek,
        [week]: data.results.map((app) => ({
          ...app,
          id: app._id,
          startDate: momentTz(app.date).tz(currentTz).format(),
          endDate: momentTz(app.date)
            .tz(currentTz)
            .add(app.duration, "minutes")
            .format(),
          title: app.patient
            ? `${app.patient.firstName} ${app.patient.lastName}`
            : app.title || "Sin título",
        })),
      }));
    },
    [token, profileInfo, appointmentsByWeek, currentTz]
  );

  useEffect(() => {
    if (appointmentsByWeek[week]) {
      return;
    }
    fetchAppointments(week);
  }, [week]);

  useEffect(() => {
    if (_.isEmpty(appointmentsByWeek)) {
      return;
    }
    setAppointmentsByWeek((_appointmentsByWeek) => {
      const newAppointmentsByWeek: Record<
        number,
        Array<CalendarAppointment>
      > = {};

      Object.keys(_appointmentsByWeek).forEach((week) => {
        newAppointmentsByWeek[+week] = _appointmentsByWeek[
          +week as keyof typeof _appointmentsByWeek
        ].map((app) => ({
          ...app,
          startDate: momentTz(app.date).tz(currentTz).format(),
          endDate: momentTz(app.date)
            .tz(currentTz)
            .add(app.duration, "minutes")
            .format(),
        }));
      });

      return newAppointmentsByWeek;
    });
  }, [currentTz]);

  const handleChangeDate = useCallback(
    (date: Date) => {
      const newWeek = moment(date).week();

      if (newWeek === week) {
        return;
      }
      setWeek(newWeek);
    },
    [week]
  );

  const handleViewNameChange = useCallback(
    (viewName: ViewNames) => {
      if (viewName !== ViewNames.Month) {
        return;
      }
      const firstWeekOfMonth = moment(week, "week").startOf("month").week();
      const lastWeekOfMonth = moment(week, "week").endOf("month").week();

      for (let _week = firstWeekOfMonth; _week <= lastWeekOfMonth; _week++) {
        if (appointmentsByWeek[_week]) {
          return;
        }

        fetchAppointments(_week);
      }
      setWeek(firstWeekOfMonth);
    },
    [week, appointmentsByWeek, fetchAppointments]
  );

  const handleCloseCancelModal = useCallback(() => {
    setAppointmentToCancel(undefined);
  }, []);

  const handleCancelledAppointment = useCallback(
    async (weekToUpdate: number) => {
      fetchAppointments(weekToUpdate);
      handleCloseCancelModal();
    },
    [appointmentToCancel, fetchAppointments]
  );

  const handleClick = useCallback(
    (date: Date | undefined) => {
      if (!date || !therapist?.accountStatus.registrationComplete) {
        return;
      }
      createAnalytic({
        action: Action.CLICK,
        componentName: COMPONENTS.MY_CALENDAR,
        data: {
          date,
        },
      });
      setNewAppointmentDate(date);
    },
    [therapist]
  );

  return (
    <>
      {newAppointmentDate && (
        <NewAppointmentModal
          newAppointmentDate={moment(newAppointmentDate)}
          onClose={() => setNewAppointmentDate(undefined)}
          onSavedAppointment={fetchAppointments}
        />
      )}
      {appointmentToCancel && (
        <CancelAppointmentModal
          appointmentToCancel={appointmentToCancel}
          onClose={handleCloseCancelModal}
          onCanceledAppointment={handleCancelledAppointment}
        />
      )}
      {!therapist?.accountStatus.registrationComplete && (
        <Box sx={{ p: 3 }}>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "1.5rem",
              mb: 1,
            }}
          >
            <CustomLink to={"/get-started"} underline>
              Completa tu perfil
            </CustomLink>{" "}
            para visualizar tu calendario
          </Typography>
        </Box>
      )}
      <Container
        sx={{
          px: { xs: 0, md: 3 },
          py: { xs: 2, md: 3 },
          position: "relative",
        }}
      >
        <Paper
          className={`transparent-scroll custom-mapsy-schedule ${therapist?.accountStatus.registrationComplete ? "" : "blurred-screen"}`}
        >
          <Scheduler
            data={Object.values(appointmentsByWeek).flat()}
            locale={"es"}
            firstDayOfWeek={1}
          >
            <ViewState
              defaultCurrentDate={currentDate}
              defaultCurrentViewName={ViewNames.Week}
              onCurrentDateChange={handleChangeDate}
              onCurrentViewNameChange={(viewName: string) =>
                handleViewNameChange(viewName as ViewNames)
              }
            />

            <DayView
              name={ViewNames.Day}
              {...hoursRange}
              cellDuration={60}
              timeTableCellComponent={(props) => {
                const numberOfWeekday = moment(props.startDate).get("d");
                const weekday = WeekDaysEnum[numberOfWeekday];
                const hour = moment(props.startDate).get("h");
                getUnavailableLocationsLength(
                  numberOfWeekday,
                  hour,
                  therapist,
                  unavailableLocations
                );

                return (
                  <CustomTimeTableCell
                    {...props}
                    noWorkableDays={
                      noWorkableDays[weekday as keyof WorkSchedule]
                    }
                    unavailableLocations={
                      unavailableLocations[`${numberOfWeekday}-${hour}`] || 0
                    }
                    handleClick={handleClick}
                  />
                );
              }}
            />
            <WeekView
              name={ViewNames.Week}
              dayScaleCellComponent={DayScaleCell}
              timeTableCellComponent={(props) => {
                const numberOfWeekday = moment(props.startDate).get("d");
                const weekday = WeekDaysEnum[numberOfWeekday];
                const hour = moment(props.startDate).get("h");
                getUnavailableLocationsLength(
                  numberOfWeekday,
                  hour,
                  therapist,
                  unavailableLocations
                );

                return (
                  <CustomTimeTableCell
                    {...props}
                    noWorkableDays={
                      noWorkableDays[weekday as keyof WorkSchedule]
                    }
                    unavailableLocations={
                      unavailableLocations[`${numberOfWeekday}-${hour}`] || 0
                    }
                    handleClick={handleClick}
                  />
                );
              }}
              {...hoursRange}
              cellDuration={60}
            />
            <MonthView name={ViewNames.Month} />

            <Toolbar
              rootComponent={({ ...props }) => (
                <CustomRootToolbar
                  {...props}
                  currentTz={currentTz}
                  setCurrentTz={setCurrentTz}
                  optionalTz={optionalTz}
                />
              )}
              flexibleSpaceComponent={FlexibleSpace}
            />
            <ViewSwitcher switcherComponent={CustomViewSwitcher} />
            <Appointments appointmentComponent={CustomAppointment} />
            <AppointmentTooltip
              layoutComponent={(props) => (
                <CustomTooltipLayout
                  {...props}
                  therapistTimezone={therapist?.timezone}
                  currentTz={currentTz}
                  onCancelButtonClicked={setAppointmentToCancel}
                />
              )}
            />
            <DateNavigator
              navigationButtonComponent={CustomDateNavigationButton}
              openButtonComponent={CustomDateNativatorOpenButton}
            />
            <TodayButton buttonComponent={CustomTodayButton} />
            <CurrentTimeIndicator
              shadePreviousAppointments={true}
              updateInterval={60 * 1000} //each 60 secs
              shadePreviousCells={false}
              indicatorComponent={TimeIndicator}
            />
          </Scheduler>
          {isLoading && <LinearProgress />}
        </Paper>
      </Container>
    </>
  );
};

export default MyCalendar;
