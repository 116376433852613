import React, { useCallback, useMemo } from "react";
import { Box, Typography } from "@mui/material";
import {
  selectReasonState,
  getTherapists,
} from "features/reason/reasonSice";
import { useAppDispatch, useAppSelector } from "hooks";
import { TherapistCard } from "./TherapistCard";
import { SuggestedPsychologistsPageProps } from "pages/SuggestedPsychologists";
import { LoadingEnum, Therapist, GetAllTherapists } from "@mapsy/shared";

interface Props extends SuggestedPsychologistsPageProps {
  getTherapistParams: GetAllTherapists;
  therapists: Therapist[]
}
export const FilteredTherapistsList: React.FC<Props> = ({
  showAllTherapists,
  getTherapistParams,
  therapists,
}) => {
  const dispatch = useAppDispatch();
  const {
    therapistsPagination,
    consultingActions,
    therapistsLoading,
  } = useAppSelector(selectReasonState);

  const fetchNextPage = useCallback(() => {
    const { predictedTopicId } = consultingActions;
    if (
      (!predictedTopicId && !showAllTherapists) ||
      therapistsPagination.isDone
    ) {
      return;
    }
    const params = showAllTherapists
      ? { getAllTherapists: true }
      : { topicId: predictedTopicId };

    dispatch(
      getTherapists({
        ...getTherapistParams,
        page: therapistsPagination.nextPage,
        ...params,
      })
    );
  }, [
    consultingActions,
    therapistsPagination,
    showAllTherapists,
    getTherapistParams,
  ]);

  if (therapists.length === 0 && therapistsLoading === LoadingEnum.succeeded) {
    return (
      <Typography>
        ¡Lo sentimos! Ningún psicólogo coincide con este criterio. Intenta con
        otra búsqueda o filtro.
      </Typography>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        gap: "1.5rem",
        flexDirection: "column",
        position: "relative",
      }}
    >
      {therapists.map((therapist, i) => (
        <TherapistCard
          key={`therapist-card-${therapist._id}-${i}-1`}
          therapist={therapist}
          isLast={i === therapists.length - 1}
          fetchNextPage={fetchNextPage}
        />
      ))}
    </Box>
  );
};
