import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { EndpointGenerator, GetAllTherapistByTopicParent, GetAllTherapists } from "@mapsy/shared";
import { RootState } from "store";
import { sortList } from "utils/sortListByProp";
import {
  Filters,
  GetAllQuery,
  LoadingEnum,
  OrderEnum,
  Parent,
} from "@mapsy/shared";
import { Therapist, GetAllPagedResult, Topic } from "@mapsy/shared";
import {
  DEFAULT_PARAMS,
  MAX_LIMIT_THERAPISTS,
} from "constants/defaultUserValues";



export interface ReasonState {
  consultingActions: {
    reason: string;
    predictedTopic: string;
    predictedTopicId: string;
  };
  topicsHash: { [id: string]: Topic };
  therapistsList: Record<number, Therapist[]>;
  topicsLoading: LoadingEnum;
  therapistsLoading: LoadingEnum;
  therapistsPagination: {
    nextPage: number;
    isDone: boolean;
    noTherapistForTopic?: boolean;
  };
}

const initialState: ReasonState = {
  consultingActions: { reason: "", predictedTopic: "", predictedTopicId: "" },
  topicsHash: {},
  therapistsList: {},
  topicsLoading: LoadingEnum.idle,
  therapistsLoading: LoadingEnum.idle,
  therapistsPagination: {
    nextPage: 1,
    isDone: false,
    noTherapistForTopic: false,
  },
};

export const getTopicList = createAsyncThunk(
  "topic/get",
  async ({
    order,
    orderBy,
  }: {
    orderBy: keyof Topic | string;
    order: OrderEnum;
  }) => {
    const endpoint = EndpointGenerator.TopicAPI.addParams({ order, orderBy });
    const topics: Topic[] = (await axios.get(endpoint)).data;
    return topics;
  }
);

export const getTherapistsByTopicParentList = createAsyncThunk(
  "therapistsByTopicParent/get",
  async ({
    parent,
    page = 1,
    limit = MAX_LIMIT_THERAPISTS,
    orderBy = "locations.price",
    order = OrderEnum.DESC,
  }: GetAllTherapistByTopicParent) => {
    const endpoint = EndpointGenerator.TherapistAPI.parentTopic({
      parent,
      page,
      limit,
      order,
      orderBy,
    });
    const therapists: GetAllPagedResult<Therapist> = (await axios.get(endpoint))
      .data;
    return therapists;
  }
);

export const getTherapists = createAsyncThunk(
  "therapists/get",
  async ({
    limit = MAX_LIMIT_THERAPISTS,
    orderBy = "locations.providedServices.price",
    order = OrderEnum.DESC,
    page = 1,
    topicId,
    getAllTherapists = false,
    filters,
  }: GetAllTherapists) => {
    const endpoint =
      !getAllTherapists && topicId
        ? EndpointGenerator.TherapistAPI.topicSibligsFiltered({
            params: { limit, order, orderBy, page },
            topicId,
          })
        : EndpointGenerator.TherapistAPI.filtered({
            limit,
            orderBy,
            order,
            page,
          });

    const therapists: GetAllPagedResult<Therapist> = (
      await axios.post(endpoint, filters)
    ).data;
    return therapists;
  }
);

export const reasonSlice = createSlice({
  name: "reasonSlice",
  initialState,
  reducers: {
    setNewConsultingActions: (
      state,
      action: PayloadAction<{
        reason: string;
        predictedTopic: string;
        predictedTopicId: string;
      }>
    ) => {
      state.consultingActions = action.payload;
    },
    resetConsultingActions: (state) => {
      state.consultingActions = {
        reason: "",
        predictedTopic: "",
        predictedTopicId: "",
      };
    },
    resetTherapistsPagination: (state) => {
      state.therapistsList = {};
      state.therapistsLoading = LoadingEnum.idle;
      state.therapistsPagination = {
        nextPage: 1,
        isDone: false,
      };
    },
  },
  extraReducers(builder) {
    builder.addCase(getTherapists.pending, (state) => {
      state.therapistsLoading = LoadingEnum.pending;
    });
    builder.addCase(
      getTherapists.fulfilled,
      (
        state,
        action: PayloadAction<GetAllPagedResult<Therapist>> & {
          meta: { arg: GetAllTherapists };
        }
      ) => {
        state.therapistsLoading = LoadingEnum.succeeded;
        const { results: newTherapists, page, totalPages } = action.payload;
        const query = JSON.stringify(action.meta.arg);

        const { topicId } = action.meta.arg;
        const defaultQuery = JSON.stringify({ ...DEFAULT_PARAMS, topicId });

        if (query === defaultQuery && !newTherapists.length) {
          state.therapistsPagination.noTherapistForTopic = true;
        }

        if (page === 1) {
          state.therapistsList = {};
        }

        state.therapistsList[page] = newTherapists;

        if (page > totalPages) {
          state.therapistsPagination.isDone = true;
          state.therapistsPagination.nextPage = 1;
          return;
        }

        state.therapistsPagination.nextPage = page + 1;
        state.therapistsPagination.isDone = false;
      }
    );
    builder.addCase(getTherapists.rejected, (state) => {
      state.therapistsLoading = LoadingEnum.failed;
    });
    builder.addCase(getTopicList.pending, (state) => {
      state.topicsLoading = LoadingEnum.pending;
    });
    builder.addCase(
      getTopicList.fulfilled,
      (state, action: PayloadAction<Topic[]>) => {
        state.topicsLoading = LoadingEnum.succeeded;
        action.payload.forEach((topic) => {
          state.topicsHash[topic._id] = topic;
        });
      }
    );
    builder.addCase(getTopicList.rejected, (state) => {
      state.topicsLoading = LoadingEnum.failed;
    });
  },
});

export const {
  setNewConsultingActions,
  resetConsultingActions,
  resetTherapistsPagination,
} = reasonSlice.actions;

export const selectReasonState = (state: RootState) => state.reasonSlice;

export default reasonSlice.reducer;
