import React, { useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";

import {
  getTherapists,
  getTopicList,
  selectReasonState,
} from "features/reason/reasonSice";
import { useAppDispatch, useAppSelector } from "hooks";
import { LoadingEnum, Modality, OrderEnum, GetAllTherapists } from "@mapsy/shared";
import { FilteredTherapistsList } from "../molecules/FilteredTherapistsList";
import { TherapistsFilters } from "../molecules/TherapistsFilters";
import { SuggestedPsychologistsPageProps } from "pages/SuggestedPsychologists";
import { EmptyTherapistList } from "./EmptyTherapistList";
import { DEFAULT_PARAMS } from "constants/defaultUserValues";

interface Props extends SuggestedPsychologistsPageProps {}

export const TherapistsList: React.FC<Props> = ({ showAllTherapists }) => {
  const dispatch = useAppDispatch();
  const {
    consultingActions: { predictedTopicId },
    therapistsLoading,
    therapistsList,
    therapistsPagination: { noTherapistForTopic },
  } = useAppSelector(selectReasonState);

  const [priceOrder, setPriceOrder] = useState<OrderEnum>(OrderEnum.DESC);
  const [modalityFilter, setModalityFilter] = useState<Modality | "Todas">(
    "Todas"
  );
  const [locationCommunity, setLocationCommunity] = useState<string>();

  const [getTherapistParams, setGetTherapistParams] =
    useState<GetAllTherapists>({
      ...DEFAULT_PARAMS,
      topicId: predictedTopicId,
      getAllTherapists: showAllTherapists,
    });

  const { topicsLoading } = useAppSelector(selectReasonState);

  useEffect(() => {
    if (topicsLoading !== LoadingEnum.idle) {
      return;
    }

    dispatch(getTopicList({ order: OrderEnum.ASC, orderBy: "name" }));
  }, [topicsLoading]);

  useEffect(() => {
    setGetTherapistParams((_params) => ({
      ..._params,
      getAllTherapists: showAllTherapists || false,
    }));
  }, [showAllTherapists]);

  useEffect(() => {
    if (modalityFilter === "Todas" && !locationCommunity) {
      setGetTherapistParams((_params) => ({
        ..._params,
        ...DEFAULT_PARAMS,
      }));
      return;
    }

    if (modalityFilter !== "Todas" && locationCommunity) {
      setGetTherapistParams((_params) => ({
        ..._params,
        page: 1,
        filters: [
          { filterBy: "locations.modality", filterValue: modalityFilter },
          { filterBy: "locations.state", filterValue: locationCommunity },
        ],
      }));
      return;
    }

    if (modalityFilter === "Todas") {
      setGetTherapistParams((_params) => {
        const filter = {
          filterBy: "locations.state",
          filterValue: locationCommunity,
        };
        return {
          ..._params,
          page: 1,
          filters: [filter],
        };
      });
      return;
    }

    if (!locationCommunity) {
      setGetTherapistParams((_params) => {
        const filter = {
          filterBy: "locations.modality",
          filterValue: modalityFilter,
        };
        return {
          ..._params,
          page: 1,
          filters: [filter],
        };
      });
    }
  }, [modalityFilter, locationCommunity]);

  useEffect(() => {
    setGetTherapistParams((_params) => ({
      ..._params,
      page: 1,
      order: priceOrder,
    }));
  }, [priceOrder]);

  useEffect(() => {
    if (therapistsLoading === LoadingEnum.pending) {
      return;
    }

    dispatch(getTherapists(getTherapistParams));
  }, [getTherapistParams]);

  const therapists = useMemo(
    () => Object.values(therapistsList).flat(),
    [therapistsList]
  );

  if (therapistsLoading === LoadingEnum.failed) {
    return (
      <Typography>
        Lo sentimos, hubo un error. Por favor contacta a soporte técnico.
      </Typography>
    );
  }

  if (
    !therapists.length &&
    therapistsLoading === LoadingEnum.succeeded &&
    !showAllTherapists &&
    noTherapistForTopic
  ) {
    return <EmptyTherapistList />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        gap: "1.5rem",
        flexDirection: "column",
      }}
    >
      <TherapistsFilters
        locationCommunity={locationCommunity}
        setLocationCommunity={setLocationCommunity}
        modalityFilter={modalityFilter}
        setModalityFilter={setModalityFilter}
        priceOrder={priceOrder}
        setPriceOrder={setPriceOrder}
      />
      <FilteredTherapistsList
        getTherapistParams={getTherapistParams}
        showAllTherapists={showAllTherapists}
        therapists={therapists}
      />
    </Box>
  );
};
