import { CircularProgress, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import t1 from "assets/img/t1.png";

import { useQuery } from "hooks/useQuery";
import { LayoutWithLogo } from "layouts/LayoutWithLogo";
import { TitledLayout } from "layouts/TitledLayout";
import { useCallback, useEffect, useState } from "react";
import { useAxios } from "hooks/useAxios";
import {
  Comment,
  CreateComment,
  ErrorMsg,
  GetAllPagedResult,
  LoadingEnum,
  OrderEnum,
  StylesEnum,
  Therapist,
  EndpointGenerator
} from "@mapsy/shared";
import { useAppSelector } from "hooks";
import { selectSessionState } from "features/session/session.slice";
import { setToken } from "utils/setToken";
import { StarsSelection } from "components/molecules/StarsSelection";
import { CustomButton } from "components/atoms/Button";
import { CustomTextarea } from "components/atoms/Textarea";
import { useTheories } from "hooks/catalogs/useTheories";
import { ProfilePic } from "components/atoms/ProfilePic";
import { CustomLink } from "components/atoms/Link";

const Review = () => {
  const query = useQuery();
  const {
    getData,
    errorMsg,
    isLoading: therapistLoading,
    postData,
    patchData,
  } = useAxios();
  const { token, profileInfo } = useAppSelector(selectSessionState);
  const { theoriesList, theoriesLoading } = useTheories();
  const [therapist, setTherapist] = useState<Therapist>();
  const [reviewNotAllowed, setReviewNotAllowed] = useState(false);
  const [score, setScore] = useState(0);
  const [comment, setComment] = useState("");
  const [savingSucceed, setSavingSucceed] = useState(false);
  const [showCommentInput, setShowCommentInput] = useState(false);
  const [foundCommentId, setFoundCommentId] = useState("");

  const fetchExistingComment = useCallback(
    async (patientId: string, token: string) => {
      const therapistId = query.get("therapistId");
      if (!therapistId) {
        return;
      }

      const endpoint = EndpointGenerator.CommentAPI.addParams({
        order: OrderEnum.ASC,
        orderBy: "createdAt",
        page: 1,
        limit: 1,
        patientId,
        therapistId,
      });
      const response: GetAllPagedResult<Comment> = await getData(
        endpoint,
        setToken(token)
      );

      if (!response.results.length) {
        return;
      }

      const savedComment = response.results[0];
      setFoundCommentId(savedComment._id);
      setScore(savedComment.score);
      setComment(savedComment.comment);
    },
    []
  );

  useEffect(() => {
    const patientId = profileInfo?.id;
    if (!patientId || !token) {
      return;
    }
    fetchExistingComment(patientId, token);
  }, [profileInfo, token]);

  const fetchTherapist = useCallback(
    async (therapistId: string) => {
      const endpoint = EndpointGenerator.TherapistAPI.urlById(therapistId);
      const therapist = await getData(endpoint, setToken(token));
      setTherapist(therapist);
    },
    [token]
  );

  const createComment = useCallback(
    async (comment: CreateComment) => {
      const endpoint = EndpointGenerator.CommentAPI.baseURL;
      const response = await postData(endpoint, comment, setToken(token), {
        409: ErrorMsg.CommentDuplicated,
      });
      if (!response?._id) {
        return;
      }
      setSavingSucceed(true);
    },
    [token, profileInfo, score, comment]
  );

  const updateComment = useCallback(
    async (comment: CreateComment, commentId: string) => {
      const endpoint = EndpointGenerator.CommentAPI.urlById(commentId);
      const response = await patchData(endpoint, comment, setToken(token));
      if (!response) {
        return;
      }
      setSavingSucceed(true);
    },
    [token, profileInfo, score, comment]
  );

  useEffect(() => {
    const patientId = query.get("patientId");

    if (!patientId || (profileInfo?.id && profileInfo.id !== patientId)) {
      setReviewNotAllowed(true);
    }

    const therapistId = query.get("therapistId");

    if (!therapistId) {
      setReviewNotAllowed(true);
      return;
    }

    fetchTherapist(therapistId);
  }, [profileInfo]);

  const handleContinue = useCallback(() => {
    if (!score) {
      return;
    }

    if (!showCommentInput) {
      setShowCommentInput(true);
      return;
    }

    if (!comment.trim()) {
      return;
    }

    const patientId = profileInfo?.id;
    const therapistId = query.get("therapistId");

    if (!patientId || !therapistId) {
      return;
    }

    const commentBody: CreateComment = {
      patientId,
      therapistId,
      score,
      comment,
    };

    if (!foundCommentId) {
      createComment(commentBody);
      return;
    }

    updateComment(commentBody, foundCommentId);
  }, [showCommentInput, score, comment, foundCommentId]);

  if (reviewNotAllowed) {
    return (
      <LayoutWithLogo>
        <TitledLayout
          title="¡Lo sentimos, ahora no puedes agregar una opinión!"
          containerSx={{ alignItems: "center", maxWidth: "sm" }}
        >
          <Grid item>
            <Typography
              sx={{
                textAlign: "center",
              }}
            >
              La URL no es correcta.
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
              }}
            >
              Da click en el correo que recibiste o en el botón "Agregar
              opinión" en{" "}
              <CustomLink to={"/patient/my_appointments"}>Mis citas</CustomLink>{" "}
              e inicia sesión.
            </Typography>
          </Grid>
          <Grid item></Grid>
        </TitledLayout>
      </LayoutWithLogo>
    );
  }

  if (
    therapistLoading ||
    theoriesLoading === LoadingEnum.pending ||
    !therapist
  ) {
    return (
      <LayoutWithLogo>
        <TitledLayout
          title="Espera un momento..."
          containerSx={{ alignItems: "center", maxWidth: "sm" }}
        >
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Grid>
        </TitledLayout>
      </LayoutWithLogo>
    );
  }

  if (savingSucceed) {
    return (
      <LayoutWithLogo>
        <TitledLayout
          title="¡Gracias compartirnos tu opinión!"
          containerSx={{
            maxWidth: "sm",
          }}
        >
          <Grid item xs={12}>
            <Typography
              sx={{
                textAlign: "center",
              }}
            >
              Gracias por compartir tu experiencia con nosotros. Tu
              retroalimentación nos ayuda a mejorar nuestros servicios
              continuamente.
            </Typography>
          </Grid>
          <Grid item>
            <CustomLink to={"/"} underline>Ir a la página principal</CustomLink>
          </Grid>
        </TitledLayout>
      </LayoutWithLogo>
    );
  }

  return (
    <LayoutWithLogo>
      <TitledLayout
        title={
          foundCommentId
            ? "Ya has compartido tu opinión, pero puedes editarla"
            : "¡Compártenos tu opinión!"
        }
        containerSx={{
          maxWidth: "sm",
        }}
      >
        <Grid container sx={{ rowGap: 4 }}>
          <Grid
            item
            xs={12}
            md={3}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ProfilePic {...therapist} />
          </Grid>
          <Grid
            item
            xs={12}
            md={9}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              px: 3,
            }}
          >
            <Typography sx={{ fontSize: "1.3rem" }}>
              {`${therapist.firstName} ${therapist.middleName}
                ${therapist.lastName}`}
            </Typography>
            <Typography>
              Enfoque{" "}
              {theoriesList.find(
                ({ value }) => value === therapist.theory.toString()
              )?.label || "No disponible"}
            </Typography>
          </Grid>
          {!showCommentInput ? (
            <>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: "1.2rem" }}>
                  {`De 1 a 5 estrellas, ¿cómo valorarías tu experiencia con 
                ${therapist.firstName}?`}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <StarsSelection score={score} setScore={setScore} />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ mt: 3, display: "flex", justifyContent: "right" }}
              >
                <CustomButton
                  customStyle={StylesEnum.primary}
                  sx={{ px: 1 }}
                  disabled={!score}
                  onClick={handleContinue}
                >
                  Continuar
                </CustomButton>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: "1.2rem" }}>
                  Escribe tu opinión:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <CustomTextarea
                  placeholder="Me pareció que..."
                  value={comment}
                  onChange={({ target }) => setComment(target.value)}
                />
              </Grid>
              {errorMsg && (
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: "1.2rem" }}>
                    {errorMsg}
                  </Typography>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                sx={{
                  mt: 3,
                  display: "flex",
                  justifyContent: "right",
                  gap: 3,
                }}
              >
                <CustomButton
                  customStyle={StylesEnum.secondary}
                  sx={{
                    padding: "12px 48px",
                  }}
                  onClick={() => setShowCommentInput(false)}
                >
                  Volver
                </CustomButton>
                <CustomButton
                  customStyle={StylesEnum.primary}
                  sx={{ px: 1 }}
                  disabled={!comment}
                  onClick={handleContinue}
                >
                  {foundCommentId ? "Actualizar" : "Enviar"}
                </CustomButton>
              </Grid>
            </>
          )}
        </Grid>
      </TitledLayout>
    </LayoutWithLogo>
  );
};

export default Review;
